<template>
  <div class="export">
    <b-dropdown
      aria-role="list"
      :triggers="['click']"
      position="is-bottom-left"
    >
      <template #trigger>
        <b-button
          label="Export"
          type="is-secondary"
        />
      </template>
      <b-dropdown-item
        aria-role="listitem"
        @click="exportAsCsv('margin1y')"
      >
        Export Predictive Margin 1y EUR
      </b-dropdown-item>
      <b-dropdown-item
        aria-role="listitem"
        @click="exportAsCsv('margin2y')"
      >
        Export Predictive Margin 2y EUR
      </b-dropdown-item>
      <b-dropdown-item
        aria-role="listitem"
        @click="exportAsCsv('margin3y')"
      >
        Export Predictive Margin 3y EUR
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
export default {
  name: 'Export',
  props: {
    gridApi: {
      type: Object,
      default: undefined,
    },
  },
  methods: {
    exportAsCsv(valueToExport) {
      if (this.gridApi) {
        this.gridApi.exportDataAsCsv({
          allColumns: true,
          fileName: 'predictive-margin.csv',
          processCellCallback(params) {
            if (!params.value) {
              return null;
            }

            if (typeof params.value === 'string') {
              return params.value;
            }

            return params.value[valueToExport];
          },
        });
      }
    },
  },
};
</script>

<style scoped>
  .export {
    display: inline-block;
  }
</style>
