<template>
  <div class="margin">
    <div class="title-section">
      <h1 class="title">
        Predictive Margins
        <span class="text-muted">(spent Monetization)</span>
      </h1>

      <div class="pred-margin-filter">
        <div class="ltv-duration">
          <b-field
            label="Predictive Margin"
            label-position="on-border"
          >
            <b-radio-button
              v-model="ltvDistance"
              native-value="1y"
              type="is-primary is-light is-outlined"
            >
              1 Year
            </b-radio-button>

            <b-radio-button
              v-model="ltvDistance"
              native-value="2y"
              type="is-primary is-light is-outlined"
            >
              2 Years
            </b-radio-button>

            <b-radio-button
              v-model="ltvDistance"
              native-value="3y"
              type="is-primary is-light is-outlined"
            >
              3 Years
            </b-radio-button>
          </b-field>
        </div>
        <export :grid-api="api || undefined" />
      </div>
    </div>

    <filters :variant-options="variantOptions" />

    <section>
      <grid
        :columns="columns"
        :data="predictiveMargin"
        :loading="$apollo.loading"
        :auto-group-column="autoGroupColumnDef"
        @ready="api = $event"
      />
    </section>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import moment from 'moment';
import countries from '../data/countries';
import Filters from '../components/Filters';
import CellRenderer from '../components/predictiveMargin/CellRenderer';
import { FILTERS } from '../plugins/filters';
import { GET_PREDICTIVE_MARGINS } from '../gql-requests';
import GridMixin from '../mixins/GridMixin';
import Export from '../components/predictiveMargin/Export';

export default {
  components: {
    Export,
    Filters,
    // eslint-disable-next-line
    CellRenderer,
  },
  mixins: [GridMixin],
  data() {
    return {
      appNames: {},
      predictiveMarginByApp: [],
      predictiveMarginObject: {},
      countries,
      api: null,
      ltvDistance: '3y',
      variantOptions: [
        {
          key: 'country',
          name: 'Country',
        },
        {
          key: 'lunigroup',
          name: 'Luni Group',
        },
      ],

    };
  },
  computed: {
    ...mapGetters('filters', ['getDateRange', 'getGranularity', 'getVariant']),
    autoGroupColumnDef() {
      return {
        headerName: `App > ${this.getVariant && this.getVariant[0].toUpperCase() + this.getVariant.slice(1)}`,
        minWidth: 300,
        field: this.isVariantProduct ? 'productName' : 'country',
        pinned: 'left',
      };
    },
    isVariantCountry() {
      return this.getVariant === 'country';
    },
    isVariantProduct() {
      return this.getVariant === 'product';
    },
    isVariantLuniGroup() {
      return this.getVariant === 'lunigroup';
    },
    timePeriods() {
      const timePeriods = [];
      const timePeriod = moment(this.getDateRange[0]);

      if (this.getGranularity === 'weekly') {
        timePeriod.date(1).day(8);
        if (timePeriod.date() > 7) {
          timePeriod.day(-6);
        }
      }

      const end = moment(this.getDateRange[1]).endOf('month');
      const fieldFormat = this.getGranularity === 'weekly' ? 'YYYYWW' : 'YYYYMM';
      const labelFormat = this.getGranularity === 'weekly' ? 'DD MMM YYYY' : 'MMMM YYYY';

      while (timePeriod.isSameOrBefore(end)) {
        timePeriods.push({
          field: timePeriod.format(fieldFormat),
          label: timePeriod.format(labelFormat),
        });
        const period = this.getGranularity === 'weekly' ? 'week' : 'month';
        timePeriod.add(1, period);
      }
      return timePeriods;
    },
    columns() {
      const columns = [{
        field: 'appName',
        colId: 0,
        headerName: 'App',
        rowGroup: true,
        hide: true,
      }];

      if (this.isVariantProduct) {
        columns.push({
          field: 'productName',
          colId: 1,
          headerName: 'Product',
          filter: true,
          sortable: true,
          pinned: 'left',
          maxWidth: 150,
          filterParams: {
            buttons: ['reset', 'apply'],
            debounceMs: 200,
          },
          menuTabs: ['generalMenuTab', 'filterMenuTab'],
          hide: true,
        });
      } else {
        columns.push({
          field: 'country',
          colId: 1,
          headerName: 'Country',
          filter: true,
          sortable: true,
          pinned: 'left',
          filterParams: {
            buttons: ['reset', 'apply'],
            debounceMs: 200,
          },
          maxWidth: 150,
          menuTabs: ['generalMenuTab', 'filterMenuTab'],
          hide: true,
        });
      }

      this.timePeriods.forEach((period) => {
        columns.push({
          field: period.field,
          colId: Number(period.field),
          headerName: period.label,
          sortable: true,
          aggFunc: this.mySum,
          // comparator: this.customComparator,
          filterParams: {
            buttons: ['reset', 'apply'],
            debounceMs: 200,
          },
          maxWidth: 170,
          menuTabs: ['generalMenuTab'],
          cellRendererFramework: 'CellRenderer',
          cellRendererParams: {
            ltvDistance: this.ltvDistance,
          },
        });
      });

      return columns;
    },
    predictiveMargin() {
      const margins = [];
      Object.keys(this.predictiveMarginObject).forEach((appId) => {
        Object.keys(this.predictiveMarginObject[appId]).forEach((dim) => {
          const row = {
            appName: this.appNames[appId],
          };

          if (this.isVariantProduct) {
            row.productName = dim;
          } else {
            row.country = dim;
          }

          // const total = 0;
          // const previousPeriod = {};
          this.timePeriods.forEach((period) => {
            if (this.predictiveMarginObject[appId][dim][period.field] === undefined) {
              row[period.field] = undefined;
            }
            // const past = this.predictiveMarginObject[appId][dim][previousPeriod.field];
            // const present = this.predictiveMarginObject[appId][dim][period.field];

            row[period.field] = this.predictiveMarginObject[appId][dim][period.field];

            // total += present && present.margins ? present.margins : 0;
            // previousPeriod = period;
          });
          // row.total = total;

          margins.push(row);
        });
      });

      return margins;
    },
  },
  watch: {
    predictiveMarginByApp() {
      this.formatMarginsAgGrid();
    },
    ltvDistance() {
      this.api.redrawRows();
    },
  },
  methods: {
    withLeadingZero(number) {
      return `0${number}`.slice(-2);
    },
    getGrowth(present, past) {
      if (!present || !past) return undefined;
      const presentTotal = present.margins;
      const pastTotal = past.margins;
      return (presentTotal - pastTotal) / pastTotal;
    },
    customComparator(a, b) {
      const valA = a.margins ? Number(a.margins) : 0;
      const valB = b.margins ? Number(b.margins) : 0;
      if (valA === valB) return 0;
      return valA > valB ? 1 : -1;
    },
    totalFormatter(params) {
      return `${FILTERS.formatNumber(params.value)} €`;
    },
    mySum(params) {
      let sumMargin1y = 0;
      let sumMargin2y = 0;
      let sumMargin3y = 0;

      params.values.forEach((value) => {
        if (value && value.margin1y) {
          sumMargin1y += value.margin1y || 0;
        }
        if (value && value.margin2y) {
          sumMargin2y += value.margin2y || 0;
        }
        if (value && value.margin3y) {
          sumMargin3y += value.margin3y || 0;
        }
      });
      return { margin1y: sumMargin1y, margin2y: sumMargin2y, margin3y: sumMargin3y };
    },
    formatMarginsAgGrid() {
      this.predictiveMarginObject = {};

      this.predictiveMarginByApp.forEach((raw) => {
        const timePeriod = this.getGranularity === 'weekly'
          ? `${raw.year}${this.withLeadingZero(raw.weekOfYear)}`
          : `${raw.year}${this.withLeadingZero(raw.month)}`;
        if (this.predictiveMarginObject[raw.appId] === undefined) {
          this.predictiveMarginObject[raw.appId] = {};
        }
        if (this.isVariantProduct) {
          if (this.predictiveMarginObject[raw.appId][raw.productSku] === undefined) {
            this.predictiveMarginObject[raw.appId][raw.productSku] = {};
          }
          this.predictiveMarginObject[raw.appId][raw.productSku][timePeriod] = { margin1y: raw.margin1y, margin2y: raw.margin2y, margin3y: raw.margin3y };
        } else {
          if (this.predictiveMarginObject[raw.appId][raw.country] === undefined) {
            this.predictiveMarginObject[raw.appId][raw.country] = {};
          }
          this.predictiveMarginObject[raw.appId][raw.country][timePeriod] = { margin1y: raw.margin1y, margin2y: raw.margin2y, margin3y: raw.margin3y };
        }

        if (this.appNames[raw.appId] === undefined) {
          this.appNames[raw.appId] = raw.appName;
        }
      });
    },
  },
  apollo: {
    predictiveMarginByApp: {
      query: GET_PREDICTIVE_MARGINS,
      skip() {
        return !this.getDateRange.length;
      },
      variables() {
        const variables = {
          weekly: this.getGranularity === 'weekly',
          variant: this.getVariant,
          isVariantCountry: this.isVariantCountry || this.isVariantLuniGroup,
          isVariantProduct: this.isVariantProduct,
        };
        if (this.getDateRange.length) {
          const from = new Date(this.getDateRange[0]);
          const to = new Date(this.getDateRange[1]);
          variables.from = `${from.getFullYear()}-${from.getMonth() + 1}`;
          variables.to = `${to.getFullYear()}-${to.getMonth() + 1}`;
        }
        return variables;
      },
    },
  },
};
</script>

<style scoped lang="scss">
.pred-margin-filter {
  display: flex;
  align-items: center;

  & > * {
    margin-left: 10px;
  }
}
</style>
