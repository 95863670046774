<template>
  <div class="cell-template">
    {{ predictiveMargin | formatNumber }} {{ predictiveMargin ? '€' : '' }}
  </div>
</template>

<script>
export default {
  name: 'CellRenderer',
  data() {
    return {
      predictiveMargin: null,
    };
  },
  beforeMount() {
    if (!this.params.value) {
      return;
    }
    if (this.params.value[`margin${this.params.ltvDistance}`]) {
      this.predictiveMargin = this.params.value[`margin${this.params.ltvDistance}`];
    }
  },
};
</script>

<style scoped lang="scss">
.cell-template {
  text-align: center;
}
</style>
